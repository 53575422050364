<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
}
</script>
<style>
iframe#webpack-dev-server-client-overlay{display:none!important}
/* button color common ly changed */
.v-btn--variant-elevated, .v-btn--variant-flat {
    background: #333399!important;
    color: #ffffff!important;
}
/* .v-btn .v-icon {
   
    color: white !important;
} */
</style>
