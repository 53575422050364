import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'sign-in',
    redirect: '/sign-in',
  },
  {
		path: '/sign-in',
		name: 'Sign-In',
		component: () => import('../views/Signin/Sign-In.vue'),
	},
  {
		path: '/user',
		name: 'user',
	
		meta:{requiresAuth:true},
		component: () => import('../views/User/user.vue'),

   
	},
	{
		path: '/user/create',
		name: 'usercreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/User/usercreate.vue'),
	},
	{
        name: 'Useredit',
        path: '/user/:id',
	
		meta:{requiresAuth:true},
		component: () => import('../views/User/useredit.vue'),

	},
	{
		path: '/role',
		name: 'role',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Role/role.vue'),
	},
	{
		path: '/role/create',
		name: 'rolecreate',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Role/create.vue'),
	},
	{
		path: '/permission',
		name: 'permission',
	
		meta:{requiresAuth:true},

		component: () => import('../views/Permission/permission.vue'),
	},
  {
		path: '/profile_list',
		name: 'profile_list',
		meta:{requiresAuth:true},

		component: () => import('../views/profile/list.vue'),
	},

	{
        name: 'edit-profile',
        path: '/editprofile',
		meta:{requiresAuth:true},

		component: () => import('../views/profile/edit.vue'),

	},
	{
        name: 'change_password',
        path: '/change_password',
		meta:{requiresAuth:true},

		component: () => import('../views/profile/changepassword.vue'),

	},
	{
        name: 'services_list',
        path: '/services_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/service/list.vue'),

	},
	{
		name: 'serviceedit',
        path: '/service/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/service/edit.vue'),

	},
	{
        path: '/service/create',
        name: '/service_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/service/create.vue'),

	},
	{
        name: 'category_list',
        path: '/category_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/category/list.vue'),

	},
	{
		name: 'categoryedit',
        path: '/category/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/category/edit.vue'),

	},
	{
        path: '/category/create',
        name: '/category_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/category/create.vue'),

	},
	{
        name: 'sub_category_list',
        path: '/sub_category_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/sub_category/list.vue'),

	},
	{
		name: 'subcategoryedit',
        path: '/subcategory/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/sub_category/edit.vue'),

	},
	{
        path: '/subcategory/create',
        name: '/sub_category_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/sub_category/create.vue'),

	},
	{
        name: 'variant_list',
        path: '/variant_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/variant/list.vue'),

	},
	{
		name: 'variantedit',
        path: '/variant/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/variant/edit.vue'),

	},
	{
        path: '/variant/create',
        name: '/variant_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/variant/create.vue'),

	},
	{
        name: 'supplier_type_list',
        path: '/supplier_type_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/supplier_type/list.vue'),

	},
	{
		name: 'supplier_typeedit',
        path: '/supplier_type/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/supplier_type/edit.vue'),

	},
	{
        path: '/supplier_type/create',
        name: '/supplier_type_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/supplier_type/create.vue'),

	},
	{
        name: 'business_type_list',
        path: '/business_type_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/BusinessType/list.vue'),

	},
	{
		name: 'business_typeedit',
        path: '/business_type/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/BusinessType/edit.vue'),

	},
	{
        path: '/business_type/create',
        name: '/business_type_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/BusinessType/create.vue'),

	},
	{
        name: 'supplier_agreement_list',
        path: '/supplier_agreement_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/supplier_agreement/list.vue'),

	},
	{
		name: 'supplier_agreementedit',
        path: '/supplier_agreement/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/supplier_agreement/edit.vue'),

	},
	{
        path: '/supplier_agreement/create',
        name: '/supplier_agreement_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/supplier_agreement/create.vue'),

	},
	{
        name: 'duration_list',
        path: '/duration_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/duration/list.vue'),

	},
	{
		name: 'durationedit',
        path: '/duration/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/duration/edit.vue'),

	},
	{
        path: '/duration/create',
        name: '/duration_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/duration/create.vue'),

	},
	{
        name: 'ticket_type_list',
        path: '/ticket_type_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/ticket_type/list.vue'),

	},
	{
		name: 'ticketedit',
        path: '/ticket/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/ticket_type/edit.vue'),

	},
	{
        path: '/ticket/create',
        name: '/ticket_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/ticket_type/create.vue'),

	},
	{
        name: 'language_type_list',
        path: '/language_type_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/language_type/list.vue'),

	},
	{
		name: 'languageedit',
        path: '/language/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/language_type/edit.vue'),

	},
	{
        path: '/language/create',
        name: '/language_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/language_type/create.vue'),

	},
	{
        name: 'cancellation_Policy_list',
        path: '/cancellation_Policy_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Cancellation_Policy/list.vue'),

	},
	{
		name: 'policyedit',
        path: '/policyedit/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Cancellation_Policy/edit.vue'),

	},
	{
        path: '/policy/create',
        name: '/policy_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Cancellation_Policy/create.vue'),

	},
	{
        name: 'review_list',
        path: '/review_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/review/list.vue'),

	},
	{
		name: 'reviewedit',
        path: '/review/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/review/edit.vue'),

	},
	{
        name: 'menu_list',
        path: '/menu_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/menu/list.vue'),

	},
	{
		name: 'menuedit',
        path: '/menu/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/menu/edit.vue'),

	},
	{
        path: '/menu/create',
        name: '/menu_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/menu/create.vue'),

	},
	{
        name: 'sub_menu_list',
        path: '/sub_menu_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/submenu/list.vue'),

	},
	{
		name: 'submenuedit',
        path: '/submenu/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/submenu/edit.vue'),

	},
	{
        path: '/submenu/create',
        name: '/submenu_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/submenu/create.vue'),

	},
	{
        name: 'third_menu_list',
        path: '/third_menu_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/thirdmenu/list.vue'),

	},
	{
		name: 'thirdmenuedit',
        path: '/thirdmenu/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/thirdmenu/edit.vue'),

	},
	{
        path: '/thirdmenu/create',
        name: '/thirdmenu_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/thirdmenu/create.vue'),

	},
	{
        name: 'prod_third_menu_list',
        path: '/prod_third_menu_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/prodthirdmenu/list.vue'),

	},
	{
		name: 'prodthirdmenuedit',
        path: '/prod_thirdmenu/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/prodthirdmenu/edit.vue'),

	},
	{
        path: '/prodthirdmenu/create',
        name: '/prod_thirdmenu_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/prodthirdmenu/create.vue'),

	},
	{
        name: 'top_tour_list',
        path: '/top_tour_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/prodtoptour/list.vue'),

	},
	{
		name: 'top_tour_edit',
        path: '/top_tour/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/prodtoptour/edit.vue'),

	},
	{
        path: '/top_tour/create',
        name: '/top_tour_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/prodtoptour/create.vue'),

	},
	{
        name: 'top_destinations_list',
        path: '/top_destinations_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/topdestination/list.vue'),

	},
	{
		name: 'top_destinations_edit',
        path: '/top_destinations/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/topdestination/edit.vue'),

	},
	{
        path: '/top_destinations/create',
        name: '/top_destinations_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/topdestination/create.vue'),

	},
	{
        name: 'product_attraction_list',
        path: '/product_attraction_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/ProductAttraction/list.vue'),

	},
	{
		name: 'product_attraction_edit',
        path: '/product_attraction/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/ProductAttraction/edit.vue'),

	},
	{
        path: '/product_attraction/create',
        name: '/product_attraction_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/ProductAttraction/create.vue'),

	},
	{
        name: 'booking_status_list',
        path: '/booking_status_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Booking_status/list.vue'),

	},
	{
		name: 'booking_status_edit',
        path: '/booking_status/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Booking_status/edit.vue'),

	},
	{
        path: '/booking_status/create',
        name: '/booking_status_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Booking_status/create.vue'),

	},
	{
        name: 'currency_list',
        path: '/currency_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Currency/list.vue'),

	},
	{
		name: 'currency_edit',
        path: '/currency/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/Currency/edit.vue'),

	},
	{
        path: '/currency/create',
        name: '/currency_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/Currency/create.vue'),

	},
	{
        name: 'status_list',
        path: '/status_list',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/status/list.vue'),

	},
	{
		name: 'status_edit',
        path: '/status/:id',
		meta:{requiresAuth:true},
		component: () => import('../views/Config/status/edit.vue'),

	},
	{
        path: '/status/create',
        name: '/status_create',
		meta:{requiresAuth:true},

		component: () => import('../views/Config/status/create.vue'),

	},
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to,from,next)=>{
	if(to.meta.requiresAuth){
		const token = localStorage.getItem('token');
if(token){
	next()

}else{
	next(
		'/sign-in'
	);
}
	}else{
		next()
	}
})
export default router
